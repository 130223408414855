<template>
  <div class="open-light" v-if="isShow">
    <el-button type="success" class="open-bt" @click="() => setDeviceMod(DEVICEMODE.Open)">
      打开射灯
    </el-button>
    <el-button type="danger " class="open-bt" @click="() => setDeviceMod(DEVICEMODE.Close)">
      关闭射灯
    </el-button>
  </div>
  <div v-else>
    请打开正确的网址
  </div>
</template>
<script lang="ts" setup>
import { useRoute, useBaseUserStore } from 'juou-managebase-ui';
import { onMounted, ref } from 'vue';
import { useApi } from '@/hooks/useApi';
import { DEVICEMODE } from '@/api';
import { ElMessage } from 'element-plus';

const userStore = useBaseUserStore();
const route = useRoute();
const isShow = ref(false);
const deviceIdValue = ref(0);


const setDeviceMod = async(mode: DEVICEMODE) => {
  try {
    await useApi().deviceApi.deviceSetModDevice(deviceIdValue.value, mode);
    ElMessage.success('操作成功');
  } catch (error) {
    ElMessage.error('操作失败，请联系管理员');
  }
};

onMounted(() => {
  const { deviceid, token } = route.query;

  if (!deviceid || !token) {
    isShow.value = false;
    return;
  }

  userStore.$patch((state) => {
    state.token = token as string;
  });

  deviceIdValue.value = Number(deviceid as string);
  isShow.value = true;
});

</script>
<style lang="scss" scoped>

.open-light {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 200px;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  border: 0;
}
.open-bt {
  width: 80%;
  height: 200px;
  font-size: 36px;
}
</style>
